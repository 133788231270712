@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

.dark {
  background-color: #111;
}

img {
  width: 20em;
  border-radius: 24px 24px 0px 0px;
}

.card img:hover {
  -webkit-transform: scale(3);
          transform: scale(3);
  transition: all 0.5s ease 1s;
  box-shadow: 0px 0px 100vw 100vh rgba(0, 0, 0, 0.8);
}

.card:hover {
  transition: all 0.5s ease .1s;
  box-shadow: 0px 0px 12px 12px rgba(0, 0, 0, 0.2);
}

.dark .card:hover {
  transition: all 0.5s ease .1s;
  box-shadow: 0px 0px 12px 12px rgba(255, 255, 255, 0.2);
}

.title img {
  height: 85px;
  width: 129px;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0px 0px 8px hsl(0deg 0% 0% / 0.2);
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.dark .header {
  background-color: #111;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.2);
}

.title {
  font-size: 64px;
  font-weight: 900;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark .title {
  color: #ddd;
}

.card {
  border-radius: 24px;
  margin: 1em;
  width: 20em;
  box-shadow: 4px 4px 8px hsl(0deg 0% 0% / 0.2);
  background-color: #fff;
}

.dark .card {
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
  color: #ddd;
  background-color: #111;
}

.cards {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.cardText {
  margin: 12px 0 12px 0;
  font-size: large;
  text-align: center;
}

.model {
  font-weight: 700;
}

::-webkit-input-placeholder {
  color: #aaa;
}

:-ms-input-placeholder {
  color: #aaa;
}

::placeholder {
  color: #aaa;
}

input {
  width: 20vw;
  border: none;
  font-size: large;
  margin: 12px;
  padding: 12px;
  border-radius: 24px;
  box-shadow: 4px 4px 8px hsl(0deg 0% 0% / 0.1);
  font-weight: 900;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  box-shadow: inset 4px 4px 8px hsl(0deg 0% 0% / 0.1);
}

input:not(:-ms-input-placeholder) {
  box-shadow: inset 4px 4px 8px hsl(0deg 0% 0% / 0.1);
}

input:not(:placeholder-shown) {
  box-shadow: inset 4px 4px 8px hsl(0deg 0% 0% / 0.1);
}

.dark input {
  background-color: #111;
  box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.2);
  color: #ddd;
}

.dark input:focus {
  outline: none;
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}

.dark input:not(:-ms-input-placeholder) {
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}

.dark input:not(:placeholder-shown) {
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}


.sortButton {
  width: 10vw;
  background-color: #fff;
  border: 0;
  font-size: 26px;
  box-shadow: 4px 4px 8px hsl(0deg 0% 0% / 0.1);
  margin: 12px;
  padding: 4px;
  border-radius: 24px;
}

.dark .sortButton {
  background-color: #111;
  box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.2);
}

.activeButton {
  box-shadow: inset 2px 2px 4px hsl(0deg 0% 0% / 0.2);
}

.dark .activeButton {
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}

.centered {
  display: flex;
  justify-content: center;
}

.themeButton {
  background-color: #fff;
  border: 0;
  border-radius: 24px;
  font-size: 28px;
  margin: 6px;
}

.dark .themeButton {
  background-color: #111;
}

footer {
  font-size: large;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
}

a {
  text-decoration: none;
  color: #333;
  font-weight: 900;
}

.dark footer {
  color: #ddd;
}

.contactLinks {
  font-size: 48px;
}

.scroll-down .header {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
}

.listCount {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  color: #aaa;
  margin: 6px;
  padding: 1px 6px;
}

.titleAndCountAndLightbulb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.countAndLightbulb {
  display: inline-flex;
  align-items: center;
}


@media only screen and (max-width:1040px) {
  .title {
    font-size: 20px;
    font-weight: 900;
  }

  input {
    width: 20vw;
    font-size: medium;
    margin: 6px;
    padding: 6px;
  }

  .sortButton {
    font-size: 16px;
    margin: 4px;
    width: 15vw;
  }

  .themeButton {
    font-size: 18px;
    margin: 4px;
  }

  img {
    width: 7em;
    border-radius: 24px;
  }

  .title img {
    height: 42px;
    width: 64px;
  }

  .card {
    border-radius: 24px;
    margin: 12px;
    padding: 12px;
    width: 7em;
  }

  .card img:hover {
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: all 0s ease 500s;
  }

  .cardText {
    margin: 6px 0 6px 0;
    font-size: medium;
  }

  .listCount {
    font-size: 10px;
  }
}
